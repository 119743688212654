@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-h1;
  }
  h2 {
    @apply text-h2;
  }
  h3 {
    @apply text-h3;
  }
  ul {
    @apply list-disc;
    @apply list-outside;
    @apply pl-8;
  }
  ol {
    @apply list-decimal;
    @apply list-outside;
    @apply pl-8;
  }
  input[type='radio'] {
    @apply accent-primary;
  }
  .mobile-only {
    @apply !hidden mobile:!flex;
  }
  .desktop-only {
    @apply mobile:!hidden;
  }
  .bold-invert {
    @apply invert;
    @apply brightness-150;
  }
}
